<template>
    <div class="container-fluid repair-kit-dashboard">
        <b-navbar
            class="row"
            sticky>
            <div class="d-flex justify-content-between w-100">
                <ul class="list-inline stat-list">
                    <li class="list-inline-item">
                        <h4><span class="title">Total time to pick</span> 00s</h4>
                    </li>
                    <li class="list-inline-item">
                        <h4><span class="title">Total time to pack</span> 00s</h4>
                    </li>
                    <li class="list-inline-item">
                        <h4><span class="title">Total orders</span> 0</h4>
                    </li>
                </ul>
                <ul class="list-inline key-list">
                    <li class="list-inline-item">
                        <i class="fal fa-hand-holding-box"></i> Est. picking time
                    </li>
                    <li class="list-inline-item">
                        <i class="fal fa-tools"></i> Repair date
                    </li>
                </ul>
            </div>
        </b-navbar>
        <div class="row">
            <div class="col area">
                <h4>Picking Area (0)</h4>
                <div class="card-grid">
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                </div>
            </div>
            <div class="col area">
                <h4>Packing Area (0)</h4>
                <div class="card-grid">
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                </div>
            </div>
            <div class="col area">
                <h4>Awaiting Collection (0)</h4>
                <div class="card-grid">
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                </div>
            </div>
            <div class="col area">
                <h4>Missing Parts (0)</h4>
                <div class="card-grid">
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                </div>
            </div>
            <div class="col area">
                <h4>Cancelled Kits (0)</h4>
                <div class="card-grid">
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                    <dashboard-list-item></dashboard-list-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>